import React from "react";
// import TwitterLogo from "../assets/svgs/twitter-logo.svg";
import XLogo from "../assets/svgs/x-logo.svg";
import TwitchLogo from "../assets/svgs/twitch-logo.svg";
import NetappLogo from "../assets/svgs/netapp-logo.svg";
import FirstdataLogo from "../assets/svgs/firstdata-logo.svg";
import FoodrescueLogo from "../assets/svgs/food-rescue-logo.svg";
import F5Logo from "../assets/svgs/f5-logo.svg";
import AMPLogo from "../assets/svgs/amp-logo.svg";
import DiemLogo from "../assets/svgs/diem-logo.svg";
import AspenmeshLogo from "../assets/svgs/aspenmesh-logo.svg";
import AmazonLogo from "../assets/svgs/amazon-logo.svg";
import MicrosoftLogo from "../assets/svgs/microsoft-logo.svg";
import MetaLogo from "../assets/svgs/meta-logo.svg";
import EaLogo from "../assets/svgs/ea-logo.svg";
import Web3Logo from "../assets/svgs/web3-logo.svg";
import CircleLogo from "../assets/svgs/circle-logo.svg";
import Neo4jLogo from "../assets/svgs/neo4j-logo.svg";
import DocusignLogo from "../assets/svgs/docusign-logo.svg";
import EffaLogo from "../assets/svgs/effa-logo.svg";
import Geo from "../assets/svgs/geometric-shape.svg";

export default {
  data: {
    page: {
      theme: {
        logo: "red",
        type: "default"
      },
      title: "About",
      uri: "/about/",
      slug: "about",
      seo: {
        canonical: "",
        metaDesc: "",
        opengraphDescription: "",
        opengraphSiteName: "Vivid",
        opengraphTitle: "About - Vivid",
        opengraphType: "article",
        title: "About - Vivid",
        twitterDescription: "",
        twitterTitle: "",
        twitterImage: null,
      },
      flexchildren: [
        {
          __typename: "HeroCta",
          backgroundGradientLight: "#171717",
          backgroundGradientDark: "#171717",
          backgroundImagePosition: "right",
          backgroundSize: "contain",
          cta: {
            target: "",
            title: "Work with us",
            url: "https://wp.Vivid.net/contact/",
          },
          ctaVariant: "light",
          description: "",
          descriptionColor: "#ffffff",
          title: "The future is collaborative.<br />\r\nLet’s build it together.",
          titleColor: "#ffffff",
          rightImage: null,
          backgroundImage: {
            altText: "",
            src: Geo,
            localFile: {
              publicURL: "/static/ce24850f0c5aff97df4444085b007ad3/geometric-shape.svg",
              extension: "svg",
              childImageSharp: null,
            },
          },
          overlapSection: true,
        },
        {
          __typename: "ApproachCards",
          cards: [
            {
              description:
                "We choose our partnerships carefully, and thoroughly vet all prospective clients to ensure a shared vision of success.",
              tag: "01",
              title: "Selective engagements",
            },
            {
              description:
                "Our clients benefit from an extensive network of proven specialists, carefully orchestrated into efficient, high-impact teams. ",
              tag: "02",
              title: "Tailored deployments",
            },
            {
              description:
                "We don’t provide services - we deliver outcomes. By focusing on results over procedures, we eliminate the inefficiencies of the traditional agency model.",
              tag: "03",
              title: "Outcome oriented",
            },
          ],
        },
        {
          __typename: "Statement",
          statement:
            "We help companies capitalize on emerging opportunities to connect, expand, and evolve in the new economy.",
          backgroundImage: {
            localFile: {
              childImageSharp: {
                fluid: {
                  base64:
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACfklEQVQ4y43USY+bMBQHcD4E+2JjlgAJYSeEJDP9wMWqVXqprxVS6SdoTqX3KsdqpK4PZiLlMB318NN79t/LBSNIkvRTlKQ/0pO5F8WZ+LLr2pu9MwHCH+A3+PW/pBcyAU79Bi6w6CJJ4kWW5YsyU+SL+ow5e6zSQr6azwCCrimfdU09a6qysBA6Y4zPGJlnGxBsLeYeI+OMTP2GdraMR4aunudzBIKNDwSbA9TBsc3Bd+1h5ZEh8MkQrdwhCr0hCtwhXMYOgMzDi5WLFr6DYK8xENgvRCv7LXi3CZ0+S6I+24Yg6Mss7qs87usiWVT5ti+TsM83fp+BMgn6Kg37Auay2O/TjdvHa7cXktjvoKFwGD3uK9ruCloXG9pUGd0XW3poCrqvc9qCU53QBrJdEdO7JqP3uy3d7yBrd7SsCtoeWioEgfs63vhdmq67Q1Mu9nXaNXXeNXncnfZld2qrrq2z7ljGXVslkEG/S7tTHnS7Ku/a47Fr2kN3d/+qEzC23qwDh6XbkNVlxsoiY0WesCxN2GYdsjzdsqpIWZWnLA49VqYR25VbFgUeMw2dRVHIyrJYVHXNBIz0956L+SpYcT+IOHZX3CYOJ47LTUy4iQhkIY/jDfc8n69Dn4eBxw3T4qKiQzW5QzAnNuIBzAs2sT9aCH8yLDzqJhrniok7WsgekWWNCKGR2Hh0iD3quj7a0GtQNU1bSLI8wmuBqoxw1ijATV/glq+Kokyqqk2aYU6abkzzGBn6ZJn6hBbGZOjaZBhQYV5VlQk+5EmciSKM1Qm+4Wl+Kd/BAwQP0kJ+gAVPvbT0V/CKnp2/rp9z4ekNPv4YnvkJ3D78f83fZn8BqktQz0EqAAEAAAAASUVORK5CYII=",
                  aspectRatio: 1.4598540145985401,
                  src: "/static/d5890d3aff47dcf03688101ead035f7d/ee604/About-Background-Image.png",
                  srcSet:
                    "/static/d5890d3aff47dcf03688101ead035f7d/69585/About-Background-Image.png 200w,\n/static/d5890d3aff47dcf03688101ead035f7d/497c6/About-Background-Image.png 400w,\n/static/d5890d3aff47dcf03688101ead035f7d/ee604/About-Background-Image.png 800w,\n/static/d5890d3aff47dcf03688101ead035f7d/f3583/About-Background-Image.png 1200w,\n/static/d5890d3aff47dcf03688101ead035f7d/5707d/About-Background-Image.png 1600w,\n/static/d5890d3aff47dcf03688101ead035f7d/53d7f/About-Background-Image.png 2886w",
                  sizes: "(max-width: 800px) 100vw, 800px",
                },
              },
            },
          },
        },
        // {
        //   __typename: "LeadershipCards",
        //   cards: [
        //     {
        //       designation: "Chief Executive Officer",
        //       linkedinUrl: "https://www.linkedin.com/in/Vividceo/",
        //       name: "Jason Williams",
        //       image: {
        //         localFile: {
        //           childImageSharp: {
        //             fluid: {
        //               base64:
        //                 "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADDElEQVQ4y2WVu0tjQRTGr4lPBA0KPvBB8AWCYhQ0EQW1SAoVCyGFICLoFiuIsbG3sRJEEGwkZUoJ+wcsamHroxDsRIVFBEEtlJjMt+fMnnN3iBc+zs3cmd+cx5yJV1JS4on48fghGyFzRPaO7Cvpg/RJeqOxP6Rjeo/LXFbA08eBBOVjmH5myOZIoPdv4nHSb1LsP+afY+qZwmbYIwf01dLSkm9qairU1dUVamtrC2VlZV80XhAoK+VCPSfkGZ1EH3PBYNDEYjHT19eHqqoq09DQ4KuystLwZrox2S2BBhQWJr0KkD0AeWTm5ubM9PQ0otEohoeH0dHRgfr6ehMKhUwgEIB4amRdQj2MkDIymJOJpru722SzWTw8PODm5gZ7e3uYmppCe3s7Ghsb1UvfAbLXnDoGHmkBeDcOlYGrq6t4eXnB/f09bm9vsb6+jtnZWYyPj6O5uRkVFRV2Y17D4Qs0ycA7DZU/kod255WVFWxvbyOVSoFCR39/PzifAwMDaGtrQ3V1tQJ5bV6Avzwnd3m2paWlNtGbm5u4urpCOp22oMXFRYyNjWF0dBQ9PT0KhORQw39n4IcAbYIVuLCwgOfnZ5ycnFivIpEIJicnEY/H0dvbC6fC6qWFMvDTBUpR0NraiouLC5yeniKZTNqCMHBoaMiPQkHFwDcXyO9UGLtgd3cXT09P2N/fRyKRsDBWOBwuBhpphJwnvekXRSptF/C5Oz8/x9raGjo7O20xJiYmMDg4WByuFuWMgcdFHvqV5uRnMhksLy+jq6sLIyMj9oA7PW0kl3psfjIwLo0ObSeeWF5ebj3Z2dnB4eGhLQx3TU1NzTfvxD6SQtrVMe1j8q7AheEFdIDN0tISNjY2MD8/b1PAZ48jcPKnTfFDby7t5xSDeDLpSzoGBwcH5vLy0rDlS4LHZMO8A0vrjaOXo0XTxC2BcqU5L3kK1XCV6Zax3tF4XgqoVU67l7R9IYCls+Vbg+y1hK8FMBIqHNCjG6Z/4zPEUUA2CJKS3Jtk3xUuIZ7ZanpeyPnr8J37C4sxoeB8TGp0AAAAAElFTkSuQmCC",
        //               aspectRatio: 1,
        //               src: "/static/e7c1758899682186e2a3e6c20ac837e6/ee604/1.png",
        //               srcSet:
        //                 "/static/e7c1758899682186e2a3e6c20ac837e6/69585/1.png 200w,\n/static/e7c1758899682186e2a3e6c20ac837e6/497c6/1.png 400w,\n/static/e7c1758899682186e2a3e6c20ac837e6/ee604/1.png 800w,\n/static/e7c1758899682186e2a3e6c20ac837e6/adfe9/1.png 828w",
        //               sizes: "(max-width: 800px) 100vw, 800px",
        //             },
        //           },
        //         },
        //       },
        //     },
        //     {
        //       designation: "Chief Strategy Officer",
        //       linkedinUrl: "https://www.linkedin.com/in/lisa-haldeman-a577284/",
        //       name: "Lisa Haldeman",
        //       image: {
        //         localFile: {
        //           childImageSharp: {
        //             fluid: {
        //               base64:
        //                 "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADS0lEQVQ4y2WVPUhbURTHX2L8Fq1BB0VF/EBFwW9BHMRUlHYRhdJB0M3i1i6mSaiLOAkiQkEcVCxpi/gx2Cwujtp0UJE6OIgUarfOYpJ7+v9f7w0vNnA499137+/9zz3nnjgej8dxmZc+KyvL5zjOK/gveL7B+AE+Cf8b9g3jN/DPHPPzPP40ww2zky9gl4AJ1grGUlJSIoWFheL3+yU3N9fO3xH8VIx+8Hq9HhjHEQIMLFlZWZmYnJxMDQwMqObmZtXX16e6uroS2dnZCfsx2CesT4tKk2EhA0thcaK2tlYdHByocDis+vv7FaH19fWqs7NTNTY28oMprH8w0M+OK35agC+gUsEIlP39fXV9fa1WVlZkaWlJxsfHpaOjQ1pbW9XQ0JAqLS0lVLmgb3XIRUVFBMaNOh0KwlK3t7dUKJeXlxKPx2VmZkaGh4elqalJoFba29sV11KAAf6FVRA2aiaSPp+Pi9T09LQ6OjqSjY0N2draks3NTUHYMjg4KAyX6nCeVqG4VAYJ/GgeElCogRMTE8JQDw8PtQ+FQtLQ0CBjY2PS3d2ts4wzFkSnDDRpGKcEnlqFBigjIyOyuLgoJycncnFxIfPz8zwGmZqaEpwfQVJcXKzNAK3SewJ/mYeUBTKcnZ0dWV1dld3dXVlYWJCysjJBlqWnp0fy8vJ0Tebk5LjD1j4DyCxzjDqTYDDITMv5+blWyLPr7e2VlpYWKS8v10BTi/8Bv9uQOQmoXkhjmVxdXcne3p4EAgENrKur04XvuknpkPF8n5EU+5JQC45Go7K2tiZtbW06EQUFBekPumAZSRl1K7SWn5+vNy0vL2uVrMGamhpxXbmMsjEfee9gI6E/XKXDM1RQohPEG3J8fCzr6+s6IaaYxUbCs7eFjXcV9uo950uCUNwpGLuKrjE0BQ08OzuTqqoqrcpcURuyLep3+uphoxdl4AAWZnZ5lQBMEs7N1dXVKhaLqbm5OfWk5tLNAfNfM5oDoB4A2Fg/8MsMG1AmKYF3qe3tbd26COR957zrLKPE2EabbrCunvgS/qctC96KSCSim6yFGP8H62a5x8AeGyyUUJn2MC8XwDD0vWYoAN6g0TKLrII7+BhsFmO/Brj+Avj7B9pouS5OZ+3/AAAAAElFTkSuQmCC",
        //               aspectRatio: 1.0101010101010102,
        //               src: "/static/a637e1fe34b489ca626df48effc040b3/ee604/2.png",
        //               srcSet:
        //                 "/static/a637e1fe34b489ca626df48effc040b3/69585/2.png 200w,\n/static/a637e1fe34b489ca626df48effc040b3/497c6/2.png 400w,\n/static/a637e1fe34b489ca626df48effc040b3/ee604/2.png 800w,\n/static/a637e1fe34b489ca626df48effc040b3/adfe9/2.png 828w",
        //               sizes: "(max-width: 800px) 100vw, 800px",
        //             },
        //           },
        //         },
        //       },
        //     },
        //     {
        //       designation: "Executive Director of Technology",
        //       linkedinUrl: "https://www.linkedin.com/in/thoth/",
        //       name: "Josh Williams",
        //       image: {
        //         localFile: {
        //           childImageSharp: {
        //             fluid: {
        //               base64:
        //                 "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAC4klEQVQ4y31UTUiiQRge/yqSErGSIA/eghRbMCgIohC1cNGTSojkxZ8NNDopRQUdNAg8ePJQ7SXo2KG7IIJ46tDF617c88rCLvv1zbvvzM5n04ftBw8zzDfzzPO+z/sOIW+fUZp/QtwgviF+IH4hfiOGiO+IR0Tog7OjzyRGN+IBoSDgP2ghNsQZA9Gxa2SfhQrtkDIxMfFqMplUnFOEKi5SpT3H40g1Mm3TH0YwPz9PFxYWAAmpIKTiP9VFUNaH7paU8Y1Wq5VubW3Rzc1Nfmh6ehrsdjvoiFVpHpINeJCU8UMHBwf08PAQisUi7O3tAZLD9vY2JzcYDDKppvQFYSbCTUUKhaNSqUCr1YJ6vQ7xeJwTx2IxmJyclI2huvATRJSGdhM1Go38ZkbS7/eh2Wzy+eXlJRQKBdjf3wc0SiZk46sYn4ios9EiEvLNLMThcAh3d3eQy+Xg/PwcarUaV+x2u7XQ9Tn9SUTR6hPM0e124fT0FFwuFwSDQchms1Aul8eFLY+8A97dhGXCN0ejUeh0OpDP54EZVCqVYGdnZxTFR4RDWaEIg8Nms8Hz8zNXxkrG6/XyPLL1McZoVcJ7U7afsppjeVpeXobBYABnZ2cQDodHjuvyJ5vSYYSP4qfqdDppIpGgyWSSOhwOmJubg3a7Dbe3t9zto6MjmJmZGReuVr9fCJKxCm+xFkMlysnJCayvr9PFxUVuxtXVFTQaDQgEApxMSole3QBhJ+l0mqncWFtbg2q1ykhVn88HmUyGLi0t0VQqBYz8g5xRSV2W953f7ycej4cg4THrjouLC7qysqLs7u4yQmYAFWZR0XIgKdPIvo5eHFRkxEQbMFRyf39fjkQisLq6CvgoKGaz+ZWRaGUkFMlEMtk/wuvra4IEZHZ21oBFTFBVCEN8mZqaAovFor00VF/0ImfZd2Ts6/V6BPuTYH0RrDcjhsiWzagsgSY8iXaS38kOd5MZ8EY0elz/AiQas4DCDGsaAAAAAElFTkSuQmCC",
        //               aspectRatio: 1,
        //               src: "/static/508d201f6cdcbaed8f033d8ac9319bd4/ee604/joshua_williams.png",
        //               srcSet:
        //                 "/static/508d201f6cdcbaed8f033d8ac9319bd4/69585/joshua_williams.png 200w,\n/static/508d201f6cdcbaed8f033d8ac9319bd4/497c6/joshua_williams.png 400w,\n/static/508d201f6cdcbaed8f033d8ac9319bd4/ee604/joshua_williams.png 800w,\n/static/508d201f6cdcbaed8f033d8ac9319bd4/adfe9/joshua_williams.png 828w",
        //               sizes: "(max-width: 800px) 100vw, 800px",
        //             },
        //           },
        //         },
        //       },
        //     },
        //     {
        //       designation: "Executive Design Director",
        //       linkedinUrl: "https://www.linkedin.com/in/fletchesketch/",
        //       name: "Fletcher Groeneman",
        //       image: {
        //         localFile: {
        //           childImageSharp: {
        //             fluid: {
        //               base64:
        //                 "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAACxLAAAsSwGlPZapAAADBklEQVQ4y3WUS0hbQRSG742PJIZUUVyI4tYXiA9wI4gbxbVNsgjREkK7VHDp2l3TZRoRdKkUQ9KFKO5UxJUICgq2CG0U2o3bbpLM3/MPM2EamguHO48z3/znnJnxvH+/gPkHxZJiX3zffwoEAn+kTXvimJkLNaz55/Od9luxb2Kw1t7ejo6ODrS2tsIZp0+sCaP+fXQWVERdZXFxsZZKpdTo6KgScE3UVjjn+GW9JmFaWNWYSiQS6urqSl1eXqpsNqvGx8eVbKIEqhw/rvnUGH7cwmRBje3u7m51cHCgXl5ecHd3h8PDQywvL6OtrU21tLQQTP+aA01YWFjsuwPkX3V2duLk5ASvr6+4vb1FsViEKEZXVxcECKMSDvDJsLwVmzOCGBL78/PzODs7w+bmJgqFAtbW1jA5OamBLI4AYX2dnK4SWHCBdueNjQ1cX19jZ2cHpVIJU1NTGBsbw+DgIEKhEGFWpXKARQJ/mk7NKNTVi8ViuL+/x83NDY6PjzEyMoKlpSXMzMzoY0QfqjTAmmGQVafb3bTz0NAQTk9PcX5+DikOpqentQ0PD7Mw2oe5bFhXaQrs7+/H7u4uHh4esL+/j3g8jtnZWT1uwm0KfP4fcGBgAHNzczrci4sLJJNJ9PT0gNUn0BamYR1ZXsktip3s6+vT121rawvr6+u6TVg4HNYbBoNBW2W3KF8JTDeUnldMq6GCfD6Pvb09DZ2YmKgXwwnXXZshMCL2wx5S8xAoKuLY9vY2Hh8fkU6ndZ/hEsbbwuIIvOpUOGpvS4qD4lCJRCJKjKHpcHgey+WyLoZ5eQjSUdBHQq+YfK6IeZ5M+LIboTnmR2AVUVdljgjMZDIql8spe+jlUBPCHFbFv2LanwkTju8JXb9j4ugJLEd1vAmykX6mFhYWlFRcAwkTI0SDKED+eRHgScE8afukehKC39vbSyBtVWBl5oeLotFo/ZFl32zG/7MA31GIjGuYjOuQ9QBDp1pxouCo9N8L9Ejav0zOGOpvsSNpf5CN39BXgD5hBur9BVMqqt+4yWB+AAAAAElFTkSuQmCC",
        //               aspectRatio: 1,
        //               src: "/static/be3ed135fdbc1bc950c2aefe308c47ee/ee604/Fletcher-Groeneman-Profile.png",
        //               srcSet:
        //                 "/static/be3ed135fdbc1bc950c2aefe308c47ee/69585/Fletcher-Groeneman-Profile.png 200w,\n/static/be3ed135fdbc1bc950c2aefe308c47ee/497c6/Fletcher-Groeneman-Profile.png 400w,\n/static/be3ed135fdbc1bc950c2aefe308c47ee/ee604/Fletcher-Groeneman-Profile.png 800w,\n/static/be3ed135fdbc1bc950c2aefe308c47ee/8f331/Fletcher-Groeneman-Profile.png 820w",
        //               sizes: "(max-width: 800px) 100vw, 800px",
        //             },
        //           },
        //         },
        //       },
        //     },
        //   ],
        //   sectionDescription:
        //     "Our leadership team is composed of industry experts with diverse and complementary skills from a variety of fields. Collectively, our expertise spans strategy, design, engineering and development. Our shared vision is the driving force behind our success.",
        //   sectionHeading: "Leadership",
        // },
        {
          __typename: "OurPartners",
          description:
            "We’ve joined forces with some of the world’s most innovative companies to create and promote data-driven products that fuel new revenue streams.",
          title: "Our partners",
          clientsLogos: [
            {
              logo: {
                altText: "Meta",
                image: <img src={MetaLogo} />,
                localFile: {
                  publicURL: "/static/7b537ce18383cb9347cf42ea4dc34d37/Path.svg",
                },
              },
            },
            {
              logo: {
                altText: "Circle",
                image: <img src={CircleLogo} />,
                localFile: {
                  publicURL: "/static/7b537ce18383cb9347cf42ea4dc34d37/Path.svg",
                },
              },
            },
            {
              logo: {
                altText: "Neo4j",
                image: <img src={Neo4jLogo} />,
                localFile: {
                  publicURL: "/static/7b537ce18383cb9347cf42ea4dc34d37/Path.svg",
                },
              },
            },
            {
              logo: {
                altText: "Electronic Arts",
                image: <img src={EaLogo} />,
                localFile: {
                  publicURL: "/static/7b537ce18383cb9347cf42ea4dc34d37/Path.svg",
                },
              },
            },
            {
              logo: {
                altText: "Web3",
                image: <img src={Web3Logo} />,
                localFile: {
                  publicURL: "/static/7b537ce18383cb9347cf42ea4dc34d37/Path.svg",
                },
              },
            },
            {
              logo: {
                altText: "Docusign",
                image: <img src={DocusignLogo} />,
                localFile: {
                  publicURL: "/static/7b537ce18383cb9347cf42ea4dc34d37/Path.svg",
                },
              },
            },
            {
              logo: {
                altText: "X",
                image: <img src={XLogo} />,
                localFile: {
                  publicURL: "/static/7b537ce18383cb9347cf42ea4dc34d37/Path.svg",
                },
              },
            },
            {
              logo: {
                altText: "Netapp",
                image: <img src={NetappLogo} />,
                localFile: {
                  publicURL: "/static/63e930e2e3e458e27eba40d9855a9085/Netapp.svg",
                },
              },
            },
            {
              logo: {
                altText: "Twitch Logo",
                image: <img src={TwitchLogo} />,
                localFile: {
                  publicURL: "/static/1b1832fd1df94b69a2070d7120c51781/Twitch-Logo.svg",
                },
              },
            },
            {
              logo: {
                altText: "",
                image: <img src={DiemLogo} />,
                localFile: {
                  publicURL: "/static/e0ae10e82103bbd5211bc4ce4a0785ba/Diem-Logo.svg",
                },
              },
            },
            {
              logo: {
                altText: "Amazong logo",
                image: <img src={AmazonLogo} />,
                localFile: {
                  publicURL: "/static/329a166d9190ddd54f203587ce7df80c/Amazong-logo.svg",
                },
              },
            },
            {
              logo: {
                altText: "Aspen mesh logo",
                image: <img src={AspenmeshLogo} />,
                localFile: {
                  publicURL: "/static/75ed82a57743e75d2ca4d8f5ceec6791/Aspen-mesh-logo.svg",
                },
              },
            },
            {
              logo: {
                altText: "First Data",
                image: <img src={FirstdataLogo} />,
                localFile: {
                  publicURL: "/static/02b29338d1e5c4e119b12d8a600bcf1e/First-Data.svg",
                },
              },
            },
            {
              logo: {
                altText: "microsoft",
                image: <img src={MicrosoftLogo} />,
                localFile: {
                  publicURL: "/static/c554efcedb7e95ad03275e7ed83a4c62/microsoft.svg",
                },
              },
            },
            {
              logo: {
                altText: "F5_Logo",
                image: <img src={F5Logo} />,
                localFile: {
                  publicURL: "/static/d83458e1e7583c6fc87e95c311232811/F5_Logo.svg",
                },
              },
            },
          ],
        },
        {
          __typename: "OurPartners",
          description:
            "We recognize the importance of a strong community and that’s why we’re committed to giving back. Through an investment of time and resources, we are proud to support the following: ",
          title: "Causes we support ",
          clientsLogos: [
            {
              logo: {
                altText: "",
                image: <img src={FoodrescueLogo} />,
                localFile: {
                  publicURL:
                    "/static/f8d0069ac155d4f262959c4c5e5937c0/Vivid-website_about_food-rescue-alliance_logo.svg",
                },
              },
            },
            {
              logo: {
                altText: "",
                image: <img src={AMPLogo} />,
                localFile: {
                  publicURL:
                    "/static/3fe4ab5c7d4a19148952c535cedababb/Vivid-website_about_amp-the-cause_logo.svg",
                },
              },
            },
            {
              logo: {
                altText: "",
                image: <img src={EffaLogo} />,
                localFile: {
                  publicURL:
                    "/static/ea896b5c1db416e4ed4ee5d7272a1fcf/Vivid-website_about_efaa_logo.svg",
                },
              },
            },
          ],
        },
      ],
    },
  },
  pageContext: {
    id: "cG9zdDoxNA==",
    databaseId: 14,
    nextPage: 17,
    previousPage: 10,
  },
};
